import { set as $set, del as $delete } from 'vue';
import { forEach, isArray, isUndefined, reduce, some } from 'lodash';
import { differenceInMinutes } from 'date-fns';
import {
  deleteDm,
  getBonusMessages,
  getChatRoomList,
  getDmHistory,
  getDmList,
  joinChatRoom,
  sendDm,
  updateDmReadTime,
} from '~/api-tc/member';
import CONSTANT_CHAT from '~/utils/constant/chat';
import CONSTANT_COMMON from '~/utils/constant/common';
import orderBy from 'lodash/orderBy';

const formatMessages = (message) => {
  const now = Date.now();
  return reduce(
    isArray(message) ? message : [message],
    (accu, msg) => {
      accu.push({
        content: '',
        addTime: now,
        ...msg,
      });
      return accu;
    },
    [],
  );
};
const NOTIFICATION_GROUP = {
  [`${CONSTANT_CHAT.channel.NOTIFY}-${CONSTANT_CHAT.room.NOTIFY_BONUS}`]:
    'notify',
};

const state = () => ({
  initStatus: false,
  activeTab: {
    channel: null,
    room: null,
    subTab: CONSTANT_CHAT.subTab.CHANNEL,
    total: 0,
    info: {},
  },
  chatList: [],
  dmList: [],
  dmListCount: 0,
  dmInfo: {},
  messages: reduce(
    CONSTANT_CHAT.channel,
    (accu, value) => {
      accu[value] = {};
      return accu;
    },
    {},
  ),
  dmLimit: {
    pageSize: 100,
    startIndex: 0,
  },
  dmListLimit: {
    pageSize: 50,
    startIndex: 0,
  },
  // 公告通知、新訊息
  notifications: {
    notify: [],
    chat: [],
  },
  latestMessageInfo: {
    channel: null,
    room: null,
    memberId: null,
  },
});

const mutations = {
  SET_INIT_STATUS: (state, status) => {
    state.initStatus = status;
  },
  SET_CHAT_LIST: (state, { data, notifyBonusEnable = false }) => {
    state.chatList = [];
    if (notifyBonusEnable) {
      state.chatList.push({
        room: CONSTANT_CHAT.room.NOTIFY_BONUS,
        channel: CONSTANT_CHAT.channel.NOTIFY,
      });
    }

    forEach(CONSTANT_CHAT.channel, (channel) => {
      forEach(data[channel], (room) => {
        state.chatList.push({ room, channel });
      });
    });
  },
  UPDATE_MESSAGE: (
    state,
    { channel, room, messages, isInit, updateType, receiveType },
  ) => {
    const now = Date.now();
    if (isUndefined(state.messages[channel]?.[room]?.value)) {
      $set(state.messages[channel], room, {
        value: [],
        lastUpdatedTime: now,
        lastReadTime: isInit ? now : null,
      });
    }

    const item = state.messages[channel][room];
    const value = item.value;
    // 歷史訊息最多存100筆
    if (updateType === CONSTANT_CHAT.updateType.PREPEND) {
      value.unshift(...messages);
    } else {
      value.push(...messages);
    }
    // 收到新訊息時
    if (receiveType === CONSTANT_CHAT.receiveType.NEW) {
      item.lastUpdatedTime = now;
      state.latestMessageInfo = {
        channel,
        room,
        memberId: messages[messages.length - 1]?.memberId ?? null,
      };
    }
    // 目前正在收到新訊息的聊天室，則更新已讀時間
    if (state.activeTab.channel === channel && state.activeTab.room === room) {
      item.lastReadTime = now;
    }
    if (value.length > CONSTANT_CHAT.messageCountLimit[channel]) {
      value.splice(0, value.length - CONSTANT_CHAT.messageCountLimit[channel]);
    }
  },
  REMOVE_MESSAGE: (state, { channel, room, message, memberId }) => {
    const value = state.messages[channel]?.[room]?.value;
    if (!value) {
      return;
    }

    const index = value.findIndex(
      (item) => item.memberId === memberId && item.content === message,
    );
    if (index < 0) {
      return;
    }

    value.splice(index, 1);
  },
  APPEND_NOTIFICATIONS: (state, { channel, room, messages }) => {
    console.log('%cAPPEND_NOTIFICATIONS', 'color: darkgreen', {
      channel,
      room,
      messages,
    });
    const name = NOTIFICATION_GROUP[`${channel}-${room}`] ?? 'chat';
    if (!state.notifications[name]) {
      $set(state.notifications, name, []);
    }
    state.notifications[name].push(...messages);
  },
  POP_NOTIFICATION: (state, { channel, room, count }) => {
    const group = NOTIFICATION_GROUP[`${channel}-${room}`] ?? 'chat';
    if (!state.notifications[group]) return;

    state.notifications[group].splice(0, count ?? 1);
  },
  CLEAR_NOTIFICATION: (state) => {
    forEach(state.notifications, (item) => {
      item.splice(0, Infinity);
    });
  },
  CLEAR_HISTORY: (state, { channel, room }) => {
    if (state.messages[channel]?.[room]) {
      state.messages[channel][room].value = [];
    }
  },
  SET_LAST_READ_TIME: (state, { room, channel }) => {
    if (state.messages[channel]?.[room]) {
      state.messages[channel][room].lastReadTime = Date.now();
    }
  },
  SET_DM_LIST: (state, payload) => {
    const item = reduce(
      payload,
      (accu, item) => {
        if (!state.dmInfo[item.targetMemberId]) {
          accu.dmList.push({
            memberId: item.targetMemberId,
            status: item.status,
          });
        }
        $set(state.dmInfo, item.targetMemberId, {
          memberId: item.targetMemberId,
          nickname: item.targetNickName,
          headImage: item.targetHeadImage,
          level: item.targetLevel,
          lastLoginTime: item.targetLastLoginTime,
          isOnline: item.isOnline,
          status: item.status,
        });
        return accu;
      },
      {
        dmList: [],
      },
    );
    state.dmList.push(...item.dmList);
    state.dmList = orderBy(
      state.dmList,
      ['isAppend', 'status'],
      ['asc', 'asc'],
    );
  },
  SET_DM_LIST_COUNT: (state, payload) => {
    state.dmListCount = payload;
  },
  APPEND_DM_LIST: (state, payload) => {
    if (state.dmInfo[payload.memberId]) {
      const index = state.dmList.findIndex(
        (item) => payload.memberId === item.memberId,
      );
      if (index > -1) {
        state.dmList.splice(index, 1);
      }
    } else {
      $set(state.dmInfo, payload.memberId, payload);
    }

    state.dmList.unshift({
      memberId: payload.memberId,
      status: payload.status,
      isAppend: true,
    });
  },
  DELETE_DM_LIST: (state, payload) => {
    $delete(state.dmInfo, payload.memberId);
    const index = state.dmList.findIndex(
      (item) => item.memberId === payload.memberId,
    );
    if (index > -1) {
      state.dmList.splice(index, 1);
      state.dmListLimit.startIndex -= 1;
    }
  },
  RESET_DM_LIST: (state) => {
    state.dmList = [];
    state.dmListCount = 0;
    state.dmInfo = {};
    state.dmListLimit.startIndex = 0;
  },
  CLEAR_DM_MESSAGES: (state) => {
    state.messages[CONSTANT_CHAT.channel.DM] = {};
  },
  SET_DM_LIMIT: (state, payload) => {
    state.dmLimit.startIndex = payload.startIndex;
    state.dmLimit.pageSize = payload.pageSize;
  },
  SET_DM_LIST_LIMIT: (state, payload) => {
    state.dmListLimit.startIndex = payload.startIndex;
    state.dmListLimit.pageSize = payload.pageSize;
  },
  SET_ACTIVE_TAB: (state, payload) => {
    forEach(payload, (value, key) => {
      state.activeTab[key] = value;
    });
  },
  UPDATE_DM_INFO: (state, { memberId, updates }) => {
    if (!state.dmInfo[memberId]) {
      return;
    }

    forEach(updates, (value, key) => {
      state.dmInfo[memberId][key] = value;
    });
  },
};

const actions = {
  init: async ({ commit, dispatch }) => {
    dispatch('actionSetActiveTab', {
      room: null,
      channel: null,
      subTab: null,
      total: 0,
      info: {},
    });
    commit('SET_INIT_STATUS', false);
    commit('SET_CHAT_LIST', { data: [], notifyBonusEnable: false });
    commit('RESET_DM_LIST');
    commit('CLEAR_DM_MESSAGES');
    commit('SET_DM_LIMIT', {
      startIndex: -10,
      pageSize: 100,
    });
    commit('SET_DM_LIST_LIMIT', {
      startIndex: 0,
      pageSize: 50,
    });
    await Promise.allSettled([
      dispatch('actionGetChatRoomList'),
      dispatch('actionGetDmList', { keyWord: '' }),
    ]);
    dispatch('actionJoinChatRoom', {
      channel: CONSTANT_CHAT.channel.LOBBY,
      room: CONSTANT_CHAT.room.LOBBY,
    });
  },
  resetDmData: ({ commit }) => {
    commit('RESET_DM_LIST');
    commit('CLEAR_DM_MESSAGES');
  },
  actionGetChatRoomList: async ({ commit, rootGetters }) => {
    const { data } = await getChatRoomList().catch(() => ({ data: {} }));
    commit('SET_CHAT_LIST', {
      data,
      notifyBonusEnable: rootGetters['initData/isBonusNotifyEnable'],
    });
    return data;
  },
  actionUpdateMessage: async ({ commit, dispatch, rootGetters }, data) => {
    switch (data.room) {
      case CONSTANT_CHAT.room.NOTIFY_BONUS:
        if (!rootGetters['initData/isBonusNotifyEnable']) {
          return;
        }

        data.message = await dispatch('actionGetGameInfo', data.message);
        break;
      case CONSTANT_CHAT.room.LOBBY:
      default:
        break;
    }
    data.updateType = data.updateType ?? CONSTANT_CHAT.updateType.APPEND;
    data.messages = formatMessages(data.message);
    commit('UPDATE_MESSAGE', data);
    commit('APPEND_NOTIFICATIONS', data);
  },
  actionGetGameInfo: async ({ dispatch }, data) => {
    const response = await Promise.allSettled(
      data.map(async (message) => {
        const gameInfo = await dispatch(
          'game/actionQueryGameInfo',
          {
            gameId: `${message.gameId}`,
            size: '500x500',
          },
          {
            root: true,
          },
        );
        return {
          ...message,
          ...gameInfo,
        };
      }),
    );
    return response.map((item) => item.value);
  },
  actionPopNotification: ({ commit }, payload) =>
    commit('POP_NOTIFICATION', payload),
  actionClearNotification: ({ commit }) => commit('CLEAR_NOTIFICATION'),
  actionJoinChatRoom: async ({ commit, dispatch, state }, payload) => {
    dispatch('actionUpdateLastReadTime', payload);
    if (
      payload.channel === CONSTANT_CHAT.channel.NOTIFY &&
      payload.room === CONSTANT_CHAT.room.NOTIFY_BONUS
    ) {
      if (!payload.isInit) {
        return state.messages[payload.channel][payload.room];
      }

      const { data } = await getBonusMessages();
      const messages = formatMessages(data);
      commit('UPDATE_MESSAGE', {
        ...payload,
        messages,
        updateType: CONSTANT_CHAT.updateType.APPEND,
      });
      commit('SET_LAST_READ_TIME', payload);
      return messages;
    }

    const result = await joinChatRoom({ roomName: payload.room }).catch(
      (error) => {
        console.log(error);
      },
    );
    if (!result) return null;
    return result;
  },
  actionClearChannelHistory: ({ dispatch, state }, forceClear = false) => {
    forEach(state.chatList, (channel) => {
      dispatch('actionClearHistory', {
        ...channel,
        forceClear,
      });
    });
  },
  actionClearAllHistory: ({ dispatch }, forceClear = false) => {
    dispatch('actionClearChannelHistory', forceClear);
  },
  actionClearHistory: ({ commit, state }, payload) => {
    if (!payload.forceClear) {
      const message = state.messages[payload.channel]?.[payload.room];
      if (
        message &&
        differenceInMinutes(Date.now(), message?.lastUpdatedTime) < 30
      ) {
        return;
      }
    }

    commit('CLEAR_HISTORY', payload);
  },
  actionGetBonusNotify: async ({ dispatch, rootGetters }) => {
    if (!rootGetters['initData/isBonusNotifyEnable']) {
      return;
    }

    dispatch('actionClearHistory', {
      channel: CONSTANT_CHAT.channel.NOTIFY,
      room: CONSTANT_CHAT.room.NOTIFY_BONUS,
      forceClear: true,
    });
    dispatch('actionJoinChatRoom', {
      channel: CONSTANT_CHAT.channel.NOTIFY,
      room: CONSTANT_CHAT.room.NOTIFY_BONUS,
      isInit: true,
    });
  },
  actionUpdateLastReadTime: ({ commit, dispatch }, payload) => {
    if (payload.channel === CONSTANT_CHAT.channel.DM && payload.room) {
      dispatch('actionUpdateDmReadTime', {
        targetMemberId: payload.room,
      });
    }
    commit('SET_LAST_READ_TIME', payload);
  },
  actionAppendDmList: ({ commit }, payload) =>
    commit('APPEND_DM_LIST', payload),
  actionJoinDm: async ({ commit, dispatch, state }, payload) => {
    commit('CLEAR_HISTORY', {
      channel: CONSTANT_CHAT.channel.DM,
      room: payload.room,
    });
    const formData = {
      targetMemberId: payload.room,
    };
    await dispatch('actionUpdateDmReadTime', formData).catch((error) =>
      console.log(error),
    );
    commit('SET_DM_LIMIT', {
      startIndex: -state.dmLimit.pageSize,
      pageSize: state.dmLimit.pageSize,
    });
    return new Promise((resolve) => {
      setTimeout(async () => {
        const formData = {
          targetMemberId: payload.room,
        };
        commit('SET_DM_LIMIT', {
          startIndex: -state.dmLimit.pageSize,
          pageSize: state.dmLimit.pageSize,
        });
        resolve(dispatch('actionGetDmHistory', formData));
      }, 0);
    });
  },
  actionGetDmHistory: async ({ commit, state }, payload) => {
    commit('SET_DM_LIMIT', {
      startIndex: state.dmLimit.startIndex + state.dmLimit.pageSize,
      pageSize: state.dmLimit.pageSize,
    });
    const formData = {
      sorts: [
        {
          columnName: 'addTime',
          orderType: 'asc',
        },
      ],
      limit: state.dmLimit,
      ...payload,
    };
    const { data } = await getDmHistory(formData).catch((error) =>
      console.log(error),
    );
    commit('UPDATE_MESSAGE', {
      channel: CONSTANT_CHAT.channel.DM,
      room: payload.targetMemberId,
      messages: data.msgs,
      updateType: CONSTANT_CHAT.updateType.APPEND,
      receiveType: CONSTANT_CHAT.receiveType.HISTORY,
    });
    return data;
  },
  actionGetDmList: async ({ commit, state }, { loadMore = false, payload }) => {
    if (loadMore) {
      commit('SET_DM_LIST_LIMIT', {
        startIndex: state.dmListLimit.startIndex + state.dmListLimit.pageSize,
        pageSize: state.dmListLimit.pageSize,
      });
    } else {
      commit('RESET_DM_LIST');
      commit('SET_DM_LIST_LIMIT', {
        startIndex: 0,
        pageSize: state.dmListLimit.pageSize,
      });
    }
    const formData = {
      sorts: [
        {
          columnName: 'status',
          orderType: 'asc',
        },
      ],
      limit: state.dmListLimit,
      ...payload,
    };
    const { data } = await getDmList(formData).catch((error) =>
      console.log(error),
    );
    commit('SET_DM_LIST', data.whisperList);
    commit('SET_DM_LIST_COUNT', data.total);
    return data;
  },
  actionDeleteDm: async ({ commit }, payload) => {
    await deleteDm({
      targetMemberId: payload.memberId,
    });
    commit('DELETE_DM_LIST', payload);
  },
  actionUpdateDmReadTime: async ({ commit }, payload) => {
    await updateDmReadTime(payload);
    commit('UPDATE_DM_INFO', {
      memberId: payload.targetMemberId,
      updates: {
        status: CONSTANT_COMMON.status.ENABLED,
      },
    });
  },
  actionSendDm: ({ commit, dispatch, rootState }, payload) => {
    sendDm(payload).catch(() => {
      commit('REMOVE_MESSAGE', {
        channel: CONSTANT_CHAT.channel.DM,
        room: payload.targetMemberId,
        message: payload.msg,
        memberId: rootState.member.memberData.memberId.value,
      });
    });
    dispatch('actionUpdateMessage', {
      channel: CONSTANT_CHAT.channel.DM,
      room: payload.targetMemberId,
      message: {
        memberId: rootState.member.memberData.memberId.value,
        content: payload.msg,
      },
      updateType: CONSTANT_CHAT.updateType.PREPEND,
      receiveType: CONSTANT_CHAT.receiveType.NEW,
    });
  },
  actionReceiveDm: ({ commit, dispatch, state }, payload) => {
    dispatch('actionAppendDmList', {
      memberId: payload.message?.targetMemberInfo?.memberId,
      nickname: payload.message?.targetMemberInfo?.nickName,
      level: payload.message?.targetMemberInfo?.level,
      headImage: payload.message?.targetMemberInfo?.headImage,
      isOnline: true,
      status: 0,
    });
    dispatch('actionUpdateMessage', {
      channel: CONSTANT_CHAT.channel.DM,
      room: payload.message?.targetMemberInfo?.memberId,
      message: {
        content: payload.message?.msg,
        nickname: payload.message?.targetMemberInfo?.nickName,
        channelName: CONSTANT_CHAT.channel.DM,
      },
      receiveType: CONSTANT_CHAT.receiveType.NEW,
      updateType: CONSTANT_CHAT.updateType.PREPEND,
    });
    // 目前不在這個私訊頻道內，才更新 status = 0 (未讀)
    if (
      state.activeTab.channel !== CONSTANT_CHAT.channel.DM ||
      state.activeTab.room !== payload.message?.targetMemberInfo?.memberId
    ) {
      commit('UPDATE_DM_INFO', {
        memberId: payload.message?.targetMemberInfo?.memberId,
        updates: {
          status: CONSTANT_COMMON.status.DISABLED,
        },
      });
    }
  },
  actionSetActiveTab: ({ commit }, payload) =>
    commit('SET_ACTIVE_TAB', payload),
};

const getters = {
  channelHasNewMsg: (state) =>
    some(
      state.messages,
      (channel, channelName) =>
        channelName !== CONSTANT_CHAT.channel.DM &&
        some(channel, (room) => room.lastUpdatedTime > room.lastReadTime),
    ),
  dmHasNewMsg: (state) =>
    some(
      state.dmInfo,
      (info) => info.status === CONSTANT_COMMON.status.DISABLED,
    ),
  hasNewMsg: (_state, getters) =>
    getters.channelHasNewMsg || getters.dmHasNewMsg,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
